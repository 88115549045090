import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grades' ,config);
}
const getOptions = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grades/options' ,config);
}

const getWithId = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grades/'+gradeId);
}

const storeGradeConversion = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/grade/conversions', formData);
}

const storeGradeConversionGrades = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/grade/conversion/grades', formData);
}

const getGradeConversionGrades = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grade/conversion/grades', config);
}

const getGradeConversions = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grade/conversions', config);
}

const deleteGradeConversionGrades = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/grade/conversion/grades/'+id);
}

const showGradeConversionGrades = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grade/conversion/grades/'+id);
}

const updateGradeConversionGrades = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/grade/conversion/grades/'+id, formData);
}

const getGradeSystems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grade/systems', config);
}
const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/grades/' + data.id, data);
    }
    return Api.post('/grades', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/grades/'+id, formData);
}
const deleteGrade = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/grades/'+id);
}
const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/grades/'+ code);
}


export default {
    getAll,
    getOptions,
    getWithId,
    storeGradeConversion,
    storeGradeConversionGrades,
    getGradeConversionGrades,
    getGradeConversions,
    deleteGradeConversionGrades,
    showGradeConversionGrades,
    updateGradeConversionGrades,
    getGradeSystems,
    store,
    update,
    deleteGrade,
    get
}
